// Remix
import type {
  LinksFunction,
  LoaderFunction,
  MetaFunction,
} from "@remix-run/node";
import { json } from "@remix-run/node";
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useLocation,
} from "@remix-run/react";
// Styles
import tailwindStylesheetUrl from "~/tailwind/tailwind.css";
import fontsStyleSheetUrl from "../styles/fonts.css";
// Providers & Hooks
import { useTranslation } from "react-i18next";
import { useChangeLanguage } from "remix-i18next";
import { GoogleTagManagerNoScript } from "~/components/utils/GoogleTagManager";
import { GLOBAL_CACHE_SECONDS, getEnv } from "~/config";
import { i18n } from "~/i18n.server";
import { isAuthorized } from "./basicAuth.server";
import CookieConsent from "./components/shared/CookieConsent";
import { HotJar } from "./components/utils/HotJar";
import MicrosoftDynamics from "./components/utils/MicrosoftDynamics";
import { TrackPageViews } from "./components/utils/useTrackPageViews";
import RootProvider from "./contexts/RootProvider";
import { pathedRoutes } from "./other-routes.server";
// Components
import { redirect } from "react-router-dom";
import { isPreviewMode } from "utils/preview-mode.server";
import { Nav } from "./components/nav";
import { PreviewBanner } from "./components/preview-banner/Preview-Banner";
import { Footer } from "./components/shared";
import { getRedirectedRoute } from "./contentful/index.server";

export const links: LinksFunction = () => {
  return [
    { rel: "stylesheet", href: tailwindStylesheetUrl },
    { rel: "stylesheet", href: fontsStyleSheetUrl },
  ];
};

export const meta: MetaFunction = () => {
  /**
   * This is the root meta tag for the project, and is applied to every page.
   * You'll probably want to customize this for your project.
   * See: https://ogp.me/
   */
  const description = "The Tony Blair Institute for Global Change";

  return {
    charset: "utf-8",
    title: "Tony Blair Institute",
    keywords: "Tony Blair,TBI,blog,research",
    "twitter:creator": "@InstituteGC",
    "twitter:site": "@InstituteGC",
    "twitter:title": "Tony Blair Institute",
    "twitter:description": description,
    viewport: "width=device-width,initial-scale=1",
  };
};

type LoaderData = {
  authorized: boolean;
  GLOBALS: string;
  GTM_ID: string | null;
  PUBLICLY_AVAILABLE_ORIGIN: string;
  locale: string;
  isInPreview: boolean;
  hasParameters: boolean;
};

export const loader: LoaderFunction = async ({ request }) => {
  // =============================================
  // Basic Auth
  // =============================================
  if (!isAuthorized(request)) {
    return json({ authorized: false }, { status: 401 });
  }

  if (pathedRoutes[new URL(request.url).pathname]) {
    return new Response();
  }

  const locale = await i18n.getLocale(request);

  // These are set in contentful. Check there for redirects.
  const url = new URL(request.url);
  const redirectedRoute = await getRedirectedRoute(url.pathname, locale);

  if (redirectedRoute && redirectedRoute.fields.to) {
    return redirect(
      `${redirectedRoute.fields.insight ? "/insights/news" : ""}${
        redirectedRoute.fields.to
      }`,
      redirectedRoute.fields.temporary ? 302 : 301
    );
  }
  //check for params
  const preview = await isPreviewMode(request);
  const hasParameters = [...url.searchParams.keys()].length > 0;

  return json<LoaderData>(
    {
      authorized: true,
      GTM_ID: getEnv("GTM_ID", { default: null }),
      PUBLICLY_AVAILABLE_ORIGIN: getEnv("PUBLICLY_AVAILABLE_ORIGIN"),
      locale,
      isInPreview: preview,
      GLOBALS: JSON.stringify({
        SENTRY_DSN: getEnv("SENTRY_DSN", { default: null }),
      }),
      hasParameters: hasParameters,
    },
    {
      status: 200,
      headers: { "cache-control": `public, s-max-age=${GLOBAL_CACHE_SECONDS}` },
    }
  );
};

export default function App() {
  let {
    locale,
    GLOBALS,
    authorized,
    GTM_ID,
    PUBLICLY_AVAILABLE_ORIGIN,
    isInPreview,
    hasParameters,
  } = useLoaderData<LoaderData>();
  let { i18n } = useTranslation();

  // This hook will change the i18n instance language to the current locale
  // detected by the loader, this way, when we do something to change the
  // language, this locale will change and i18next will load the correct
  // translation files
  useChangeLanguage(locale);

  const { pathname } = useLocation();
  const canonicalUrl = `${PUBLICLY_AVAILABLE_ORIGIN}${pathname}`;

  if (!authorized) {
    return <p>Unauthorized</p>;
  }

  return (
    <html lang={locale} className="h-full" dir={i18n.dir()}>
      <head>
        <Meta />
        {hasParameters && <meta name="robots" content="noindex" />}
        <Links />
        <link rel="canonical" href={canonicalUrl} />
        <link rel="preconnect" href="https://mktdplp102cdn.azureedge.net" />
        <link rel="preconnect" href="https://images.ctfassets.net" />
      </head>
      <body>
        <RootProvider>
          <GoogleTagManagerNoScript gtmId={GTM_ID} />
          <HotJar />
          <TrackPageViews />
          <MicrosoftDynamics />
          {/* <WelcomeTransition /> */}
          {isInPreview && <PreviewBanner />}
          <a
            className="aeonik-base absolute z-[999] translate-y-[-100%] p-4 text-white underline mix-blend-difference focus:translate-y-0 focus:outline-none"
            href="#main-content"
          >
            Skip to content
          </a>
          <Nav />
          <main id="main-content" className="overflow-x-hidden bg-white">
            <Outlet />
          </main>
          <Footer />
          <CookieConsent />
        </RootProvider>

        <ScrollRestoration />
        <script
          suppressHydrationWarning
          dangerouslySetInnerHTML={{ __html: `window.GLOBALS=${GLOBALS}` }}
        />
        <Scripts />
        <LiveReload />
      </body>
    </html>
  );
}
